$header-toggle-colour: rgb(129, 129, 129);

.header-toggle {
    display: flex;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    height: 40px;
    padding: 0;
    margin-bottom: 10px;
    background: none;
    border: 0;
    border-radius: 0;
    font-family: inherit;
    font-size: 1.0rem;
    -webkit-appearance: none;
}

.header-toggle__title {
    font-weight: bold;
    color: $header-toggle-colour;
}

.header-toggle__divider {
    flex: 1;
    display: block;
    margin: 0 20px;
    height: 1px;
    opacity: 0.3;
    background-color: $header-toggle-colour;
}

.header-toggle__icon {
    flex: 0 0 15px;
    height: 15px;
    margin-right: 15px;
    path {
        fill: $header-toggle-colour;
    }
}