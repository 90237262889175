@import "../../styles/vars";

.sort-bar {
    display: flex;
    align-items: center;
    height: 42px;
    padding: 0 15px;
    border-radius: $list-radius;
    background-color: $content-background-colour;
    color: $font-colour-light;
    box-shadow: $list-shadow;
    margin-bottom: 15px;
}

.sort-bar__name {
    font-size: 0.8rem;
}

.sort-bar__options {
    display: flex;
    height: 100%;
    margin-left: 10px;
}

.sort-bar__option {  
    &:not(:last-child) {
        margin-right: 4px;
    }
}

.sort-bar__option-pressable {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0;
    border: 0;
    background-color: transparent;
    border-radius: 0;
    -webkit-appearance: none;
}

.sort-bar__option-button {
    font-size: 0.8rem;
    font-family: inherit;
    background-color: lighten($content-background-colour, 10%);
    border-radius: 4px;
    border: 0;
    color: $font-colour-light;
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
}

.sort-bar__option-button:active {
    box-shadow: $list-shadow inset;
}

.sort-bar__option {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}

.sort-bar__option-active {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 10px;
    overflow: hidden;

    &:before {
        content: '';
        position: relative;
        bottom: -6px;
        width: 10px;
        height: 10px;
        background-color: rgba(#fff, 0.2);
        transform: rotate(45deg);
    }
}

.sort-bar__direction-pressable {
    border: 0;
    background-color: transparent;
    padding: 0;
    height: 100%;
    margin-left: auto;
    -webkit-appearance: none;
}

.sort-bar__direction-button {
    position: relative;
    width: 20px;
    height: 20px;
    padding: 0;
    background-color: lighten($content-background-colour, 10%);
    border-radius: 4px;
    border: 0;
}

.sort-bar__direction-icon {
    position: absolute;
    top: 2px;
    left: 0;
    width: 100%;
    height: calc(100% - 4px);
    opacity: 0.3;

    path {
        fill: $font-colour-light;
    }
}

.sort-bar__direction-icon--active {
    opacity: 1;
}