@import "../../styles/vars";

.content-container {
    max-width: $container-width;
    padding: 0 $container-padding;
    margin: 0 auto;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
}