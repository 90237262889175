@import "../../styles/vars";

.providers-selection {
    max-height: 250px;
    overflow: auto;
    border: $divider;
    background-color: rgba(#fff, 0.1);
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.providers-selection__list-item-label {
    display: flex;
    align-items: center;
}

.providers-selection__list-item-image {
    display: block;
    width: 40px;
    height: 40px;
}

.providers-selection__list-item-content {
    flex: 1;
    margin-left: 15px;
}

.providers-select__checkbox {
    padding: 5px;
}
