@import "../../styles/vars";

.loader {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 5px solid $call-to-action-colour;
    border-top-color: transparent;
    animation: loader-spin 1000ms ease-in-out infinite;
}

@keyframes loader-spin {
    to {
        transform: rotate(360deg);
    }
}