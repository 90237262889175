@import "../../styles/vars";

.site-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    color: $font-colour-light;
    background: $content-background-colour;
    box-shadow: 0 -10px 11px 9px rgba(#000, 0.6);
    z-index: 2;
}

.site-header__title {
    flex: 1;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1;
    text-align: center;
}

.site-header__button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 60px;
    height: 60px;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;
}

.site-header__icon {
    width: 20px;
    height: 20px;

    path {
        fill: $font-colour-light;
    }
}

.site-header__spacer {
    height: 60px;
}