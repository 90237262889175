@import "../../styles/vars";

.movies__list-item {
    position: relative;
    display: flex;
    overflow: hidden;
    background-color: $content-background-colour;
    border-radius: $list-radius;
    box-shadow: $list-shadow;
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.movies__list-item-locator {
    position: absolute;
    top: -60px; // Header height
}

.movies__list-item-pressable {
    flex: 1;
    display: flex;
    background: none;
    border: 0;
    border-radius: 0;
    text-align: left;
    font-family: inherit;
    font-size: 1rem;
    padding: 0;
    -webkit-appearance: none;
}

.movies__list-item-image-container {
    flex: 0 0 60px;
    position: relative;
    background-color: #000;
}

.movies__list-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 20px;
}

.movies__list-item-title {
    color: $font-colour-light;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1;
}

.movies__list-item-watch-action {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 45px;
    background-color: transparent;
    border: 0;
    border-left: $divider;

    &:hover {
        background-color: lighten($content-background-colour, 10%);
    }

    &:active {
        box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.2) inset;
    }
}

.movies__list-item-action-icon {
    width: 15px;
    height: 15px;

    path {
        fill: $font-colour-light;
    }
}