@import "../../styles/vars";

.btn {
    display: flex;
    align-items: center;
    padding: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    font-size: 1rem;
    font-family: $font-family;
    line-height: 1;
    -webkit-appearance: none;

    &:active {
        opacity: 0.8;
        transform: scale(0.95);
    }
}

.btn__icon {
    width: 12px;
    margin-right: 10px;
    path {
        fill: currentColor;
    }
}

.btn--primary {
    height: 40px;
    padding: 0 20px;
    border-radius: 20px;
    background-color: $call-to-action-colour;
    color: #fff;

    &:hover {
        background-color: $call-to-action-hover-colour;
    }

    &.btn--large {
        height: 46px;
        border-radius: 23px;
    }
}

.btn--secondary {
    height: 40px;
    padding: 0 10px;
    color: $font-colour-light;

    &:hover {
        color:$font-colour-medium
    }

    &.btn--warning {
        color: #c74d4d;
    
        &:hover {
            color: lighten(#c74d4d, 10%);
        }
    }
}

.btn--warning {
    color: #c74d4d;

    &:hover {
        color: lighten(#c74d4d, 10%);
    }
}