@import "../../styles/vars";

.add-film-button {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-bottom: 30px;
}

.add-film-button__container {
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: $container-width;
    padding: 0 $container-padding;
}

.add-film-button__spacer {
    height: 76px;
}
