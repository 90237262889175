@import "../../styles/vars";

.modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.6);
    z-index: 1;
}

.modal__container {
    position: relative;
    width: 90%;
    max-width: 400px;
    max-height: 90vh;
    background-color: $content-background-colour;
    box-shadow: $list-shadow;
    color: $font-colour-light;
    border-radius: 5px;
    overflow: auto;
    z-index: 2;
}

$modal_close_width: 40px;
$modal_close_icon_width: 20px;
$modal_header_horiz_padding: 20px;

.modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding-left: $modal_header_horiz_padding;
    padding-right: $modal_header_horiz_padding - ($modal_close_width - $modal_close_icon_width)/2;
}

.modal__title {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1;
}

.modal__close {
   width: $modal_close_width;
   height: $modal_close_width;
   margin-left: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   border: none;
   border-radius: 0;
   background-color: transparent;
   -webkit-appearance: none;
}

.modal__close-icon {
    width: $modal_close_icon_width;
    height: $modal_close_icon_width;

    path {
        fill: $font-colour-light;
    }
}

.modal__close:hover {
    opacity: 0.8;
}

.modal__content {
    padding: 0 20px 20px;
}