@import "../../styles/vars";

.edit-list-modal__field {
    display: block;
    margin-bottom: 15px;
}

.edit-list-modal__field-label {
    display: block;
    margin-bottom: 5px;
}

.edit-list-modal__field-input {
    width: 100%;
    height: 35px;
    padding: 0 15px;
    font-size: 1rem;
    font-family: inherit;
    color: #fff;
    line-height: 35px;
    background-color: $input-background-colour;
    border: $divider;
    border-radius: 5px;

    &:focus {
        outline: none;
        border-color: $call-to-action-colour;
    }
}