@import "../../styles/vars";

.welcome {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
}

.welcome__card {
    width: calc(100% - #{$container-padding * 2});
    max-width: 360px;
    padding: 20px;
    text-align: center;
    color: $font-colour-light;
}

.welcome__title {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 15px;

}

.welcome__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcome__attributions {
    font-size: 0.8rem;
}