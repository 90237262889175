@import "../../styles/vars";

.providers {
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.providers__title {
    font-weight: bold;
    margin-bottom: 8px;
}

.providers__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;

    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.providers__list-item {
    position: relative;
    border-radius: 5px;
    box-shadow: $list-shadow;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
}

.providers__list-item-image {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.providers__data {
    font-size: 0.8rem;
}