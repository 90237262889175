$font-size: 16px;
$font-family: "Roboto", sans-serif;
$font-colour-light: rgba(#ebe9e9, 0.9);
$font-colour-medium: rgba(#ebe9e9, 0.6);

$container-padding: 20px;
$container-width: 640px + 2*$container-padding;

$list-radius: 5px;
$list-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.4);

$content-background-colour: #2e343b;
$content-background-gradient: linear-gradient(0deg, rgba(46,52,59,1) 0%, rgba(58,66,76,1) 100%);

$call-to-action-colour: #619bc2;
$call-to-action-hover-colour: darken($call-to-action-colour, 10%);

$divider: 1px solid lighten($content-background-colour, 10%);

$input-background-colour: rgba(#fff, 0.2);