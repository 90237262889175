
@import "../../styles/vars";

.movie-search__search {
    display: flex;
    height: 40px;
}

.movie-search__search-input {
    position: relative;
    display: block;
    flex: 1;
    color: #fff;
    background-color: $input-background-colour;
    border: $divider;
    border-top-left-radius: 5px;
    overflow: hidden;
}

.movie-search__search-label {
    font-size: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
}

.movie-search__search-input-field {
    width: 100%;
    font-family: inherit;
    font-size: 1rem;
    padding: 0 15px;
    height: 40px;
    line-height: 38px;
    color: $font-colour-light;
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid $input-background-colour;
}

.movie-search__search-input-field:focus {
    outline: 0;
    border-color: $call-to-action-colour;
}

.movie-search__search-btn {
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: $call-to-action-colour;
    border: 0;
    border-top-right-radius: 5px;
}

.movie-search__search-btn-icon {
    width: 20px;
    height: 20px;
    path {
        fill: #fff;
    }
}

.movie-search__results {
    position: relative;
    height: 300px;
    max-height: 75vh;
    border: $divider;
    border-top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: auto;
}

.movie-search__results-message {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.movie-search__results-loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, 0.1);
    z-index: 2;
}

.movie-search__results-list {
    position: relative;
    z-index: 1;
}

.movie-search__results-list-item-image-container {
    position: relative;
    background-color: #000;
    flex: 0 0 50px;
}

.movie-search__results-list-item-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    background-color: rgba(#fff, 0.1);
    border-bottom: 1px solid darken($content-background-colour, 2%);
    overflow: hidden;
}

.movie-search__results-list-item-title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    color: $font-colour-light;
    line-height: 1;
    margin-bottom: 8px;
}

.movie-search__results-list-item-pressable {
    display: flex;
    align-items: stretch;
    width: 100%;
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    border-radius: 0;
    font-family: inherit;
    font-size: 1rem;
    text-align: left;
    -webkit-appearance: none;
}