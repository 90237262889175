@import "../../styles/vars";

.lists_list {
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.lists__list-item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background-color: $content-background-colour;
    border-radius: $list-radius;
    box-shadow: $list-shadow;
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.lists__list-link {
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 100%;
   height: 100%;
   color: inherit;
   text-decoration: none;
   line-height: 1;
}

.lists__list-new-form {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.lists__list-new-input {
    display: block;
    width: 100%;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
    color: $font-colour-light;
    height: 100%;
    border: 0;
    background-color: transparent;
    border-radius: 0;
    outline: 0;
}

.lists__list-item-title {
    color: $font-colour-light;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1;
}

.lists__list-item-details {
    font-size: 0.8rem;
    color: $font-colour-medium;
    line-height: 1;
}