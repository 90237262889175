@import "vars";
@import "./node_modules/reset-css/sass/reset";

html {
    font-size: $font-size;
}

body {
    font-family: $font-family;
    color: #111;
    line-height: 1.4;
    background: rgb(23,23,23);
    background: linear-gradient(180deg, rgba(23,23,23,1) 0%, rgba(46,46,46,1) 100%); 
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

*,*::before,*:after {
    box-sizing: border-box;
}

.poster-image-container {
    position: relative;
    &:before {
        content: '';
        display: block;
        padding-top: 231/154 * 100%;
    }
}

.image-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.full-screen-loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

p:not(:last-child) {
    margin-bottom: 15px;
}

.fixed-loading-indicator-container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding: 20px;
    pointer-events: none;
    z-index: 2;
}

a {
    color: $call-to-action-colour;
}