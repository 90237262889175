.sync-indicator {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    background-color: #111;
    border-radius: 50%;
    pointer-events: initial;
}

.sync-indicator__icon {
    display: block;
    width: 50%;
    height: 50%;
    animation: sync-rotate 1000ms linear infinite;

    path {
        fill: #fff;
    }
}

@keyframes sync-rotate {
    to {
        transform: rotate(360deg);
    }
}