@import "../../styles//vars";

.list-item-meta-details {
    font-size: 0.8rem;
    color: $font-colour-medium;
}

.list-item-meta-details__list {
    display: flex;
}

.list-item-meta-details__list-item {
    &:not(:last-child) {
        margin-right: 10px;
    }
}