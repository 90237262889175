@import "../../styles/vars";

.movie-form__movie {
    display: flex;
    margin-bottom: 15px;
}

.movie-form__movie-image-container {
    flex: 0 0 60px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #000;
    overflow: hidden;
}

.movie-form__movie-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: $divider;
    border-left: 0; 
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 15px;
    overflow: hidden;
}

.movie-form__movie-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 8px;
}

.movie-form__actions {
    display: flex;
    justify-content: space-between;

    .btn:only-child {
        margin-left: auto;
    }
}